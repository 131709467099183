@use "sass:math";

@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

.dialouge {
  max-width: breakpoint("tiny-small");

  &Inner {
    padding-top: size("gutter");
    padding-bottom: size("gutter");

    @media (max-width: (breakpoint("small") - 1px)) {
      a {
        word-wrap: break-word;
      }
    }
  }
}
