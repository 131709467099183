@import "../../node_modules/@crossroads/shop-views/src/shop-views/CheckoutCart";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.container {
  margin-top: size("gutter--large") * 2;

  @media (max-width: breakpoint("small")) {
    margin-top: size("gutter--large");
  }
}
