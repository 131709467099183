@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

$padding: size("gutter--large");
$padding--small: 1.2rem;

:export {
  headerHeight: strip-unit(size("header-height"));
  bannerHeight: strip-unit(size("banner-height"));
  breakpointTiny: strip-unit(breakpoint("tiny"));
}

.wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.spacer {
  margin-bottom: size("gutter--large");
}

.filterWrapper {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 0;

  @media (max-width: breakpoint("tiny-small")) {
    flex-direction: column;
  }
}

.stickyWrapper {
  position: sticky;
  top: size("header-height");
  z-index: z-index("popup");
}

.filterbarFixed {
  box-shadow: -1px 3px 15px 0 rgb(141 151 166 / 0.16);
}

.filterbar {
  z-index: z-index("popup");
}

.filterBarHint {
  height: 89px;
}

.filterButton,
.sortButton {
  color: color("text") !important;
  flex: 1;
  font-weight: 700 !important;
  text-transform: none !important;
}

.filterIcon {
  height: 36px;
  width: 36px;
}

.sortIcon {
  height: 36px;
  width: 36px;
}

.block {
  background: color("body");
  overflow: hidden;
  padding-bottom: size("gutter");
  padding-left: $padding--small;
  padding-right: $padding--small;

  @media (min-width: breakpoint("medium")) {
    padding-left: $padding;
    padding-right: $padding;
  }

  @media (max-width: breakpoint("tiny") - 1px) {
    padding-bottom: size("gutter--small");
  }
}

.body {
  display: flex;
  flex-direction: column;
}

.text {
  display: flex;
  font-size: size("font--medium");
  flex-wrap: wrap;

  @media (max-width: breakpoint("tiny") - 1px) {
    flex-flow: column nowrap;
  }

  span {
    display: inline-block;
    word-wrap: none;
    white-space: nowrap;
    margin-top: size("grid-gutter");
  }

  .split {
    display: flex;

    @media (max-width: breakpoint("tiny") - 1px) {
      flex-direction: column;
    }
  }

  .title {
    font-weight: bold;
  }
}
