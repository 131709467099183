@import "../../node_modules/@crossroads/shop-views/src/shop-views/AdventCalendarHero";
@import "ui-components/_variables.scss";

.hero {
  margin-top: -#{size("header-margin")};
}

.breadcrumbs {
  margin: size("gutter") 0;
  z-index: z-index("popup");
}

.banner {
  min-height: 400px;
  border-radius: size("border-radius");
  overflow: hidden;
  position: relative;
}

.bannerSmall,
.bannerMedium,
.bannerLarge {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
}

.bannerSmall {
  @media (max-width: 440px) {
    display: block;
  }
}

.bannerMedium {
  @media (min-width: 441px) and (max-width: 800px) {
    display: block;
  }
}

.bannerLarge {
  @media (min-width: 801px) {
    display: block;
  }
}

.totalCountWrapper {
  margin-top: size("gutter--large");
  margin-bottom: size("gutter--small");
}

.totalCount {
  font-size: size("font--medium");
}

