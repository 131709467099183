@use "sass:math";

@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

.donationInfo {
  color: color("secondary");
  font-size: size("font--small");
  margin-top: size("gutter--small");
}
