@use "sass:math";

@import "theme/ui-components/_variables";

$content-margin: size("gutter--section");
$content-margin--medium: math.div(size("gutter--section"), 2);
$content-margin--small: size("gutter--large");
$hero-height: 450px;

.hero {
  width: 100%;
  top: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: breakpoint("small") - 1px) {
    flex-wrap: wrap;
  }

  img {
    object-fit: cover;
    width: 100%;
  }
}

.image {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @media (max-width: breakpoint("small") - 1px) {
    position: static;
  }
}

.wrapper {
  width: 100%;
}

.contentBlock {
  background-color: #fff;
  width: 100%;
  margin: size("gutter--large") 0;
  padding: size("gutter--large");
  border-radius: size("border-radius");
  position: relative;

  @media (min-width: breakpoint("medium")) {
    width: 50%;
    margin: (size("gutter") * 4) 0;
    padding: size("gutter") * 2;
  }

  @media (max-width: breakpoint("small") - 1px) {
    padding: 0;
    text-align: center;

    button {
      width: 100%;
    }
  }
}

.heading {
  margin: size("gutter--tiny") 0;
  font-weight: 600;
  font-size: size("font--huge");
  line-height: math.div(font("line-height"), 1.2);

  @media
    all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    margin-bottom: size("gutter");
  }

  @media (min-width: breakpoint("small")) {
    font-size: size("font--enormous");
  }
}

.subHeading {
  color: color("text");
  margin: size("gutter--tiny") 0;
  font-size: size("font--large");
  line-height: math.div(font("line-height"), 1.1);

  @media (min-width: breakpoint("small")) {
    font-size: size("font--huge");
  }
}

.splash {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 200px;
  color: #fff;
  background: color("secondary");
  background: linear-gradient(90deg, color("primary") 0%, color("secondary") 100%);
  background: url("/assets/trapezoid.svg#trapezoid") center center / contain no-repeat;
  transform: rotate(15deg);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  right: -85px;
  margin: -50px 0 0;

  @media (min-width: breakpoint("medium")) {
    position: absolute;
    top: -40%;
    right: -15%;
    margin: 0;
  }


  > h3 {
    transform: rotate(-15deg);
    max-width: 80%;
    font-weight: font("weight--bold");
  }
}

.text {
  color: color("text");
  margin: 0 0 size("gutter");
  font-size: size("font");
  line-height: math.div(font("line-height"), 1.1);
}

.cta {
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 7px);
    left: 12px;
    width: 9px;
    height: 14px;
    background-repeat: no-repeat;
    background-size: 9px 14px;
  }

  @media (max-width: breakpoint("small") - 1px) {
    width: 100%;
  }
}

.slotLeft {
  border-radius: 50%;
  background-color: color("primary");
  color: #fff;
}
