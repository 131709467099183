@use "sass:math";

@import "../../node_modules/@crossroads/ui-components/src/ui-components/AdventCalendar.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";
$paddingX: 10px;
$paddingY: 5px;

.door {
  border-radius: size("border-radius");
  height: 0;
  padding-bottom: 100%;
  background: #fff;
  position: relative;

  .img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &Text {
    display: flex;
    flex-direction: column;
    height: 100%;

    h2:empty {
      display: none;
    }

    h3 {
      text-transform: none;
      padding: $paddingY $paddingX;

      &:last-child {
        margin: auto;
        font-size: size("font--huge");
        text-transform: uppercase;
        top: - size("font--mini");
        position: relative;

        @media (max-width: 802px) {
          font-size: size("font--medium");
        }
      }

      @media (max-width: 802px) {
        font-size: size("font");
      }
    }

    h3,
    h2 {
      margin-top: 0;
      margin-bottom: auto;
    }
  }

  &HoverDim {
    opacity: 0.15;
  }
}

.container {
  margin: math.div(- size("gutter"), 2);
}

.column {
  width: 100%;
  padding: math.div(size("gutter"), 2);

  @media (min-width: breakpoint("tiny-small")) {
    width: 50%;
  }

  @media (min-width: breakpoint("medium")) {
    width: 25%;
  }
}
