@import "../../node_modules/@crossroads/ui-components/src/ui-components/Input";

$floatLabelOffset: -0.7rem;

.block {
  position: relative;

  &.float {
    label {
      text-transform: none;
      color: color("text--muted") !important;
    }

    input:disabled {
      border: 1px solid color("border");
    }
  }
}

.input {
  border: 1px solid color("border--muted");
}

.error .input {
  background: #fbeeee;
}

.float .input:focus + .label,
.float:not(.empty) .label {
  font-weight: 400;
  font-size: size("font--mini");
  transform: translateY($floatLabelOffset);
}

