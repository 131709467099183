@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";
@import "../../node_modules/@crossroads/ui-components/src/ui-components/AppFooter";

.footer {
  background: color("background--dark");
  color: color("text--inverted");

  .heading {
    font-size: size("font--medium");
    color: color("text--inverted");
    margin-bottom: size("gutter--small");
  }
}

.nav,
.col nav {
  a,
  .link {
    color: color("text--inverted");
    padding: size("grid-gutter") 0;
  }
}

.cols > div {
  @media (min-width: (breakpoint("tiny"))) and (max-width: (breakpoint("small") - 1px)) {
    &:nth-child(2) {
      text-align: end;

      nav {
        align-items: flex-end;
      }
    }
  }
}

.contentTerms {
  * {
    font-size: size("font--small");
    color: color("text--inverted");
  }

  a,
  button,
  button > span {
    color: color("tertiary");
  }
}
