@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

.customerServiceLink {
  margin-top: size("gutter--large");
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  flex: 1 0 auto;
  text-align: center;

  a {
    display: inline-block;
    text-decoration: underline;
    margin-top: size("gutter--mini");
  }
}
