@import "../../node_modules/@crossroads/shop-views/src/shop-views/ProductListHero";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$padding: size("gutter--large") * 1.2;

.productListHero {
  color: #fff;
  background: color("secondary");
}

.content {
  padding: $padding 0;

  .breadcrumbs {
    a,
    span {
      color: #fff !important;
    }
  }
}
