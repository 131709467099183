@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";
@import "../../node_modules/@crossroads/shop-views/src/shop-views/SuccessView.scss";

.checkIconBG {
  svg {
    width: 44px;
    height: 44px;
  }
}
