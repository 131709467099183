@use "sass:math";

@import "../../node_modules/@crossroads/ui-components/src/ui-components/Filterbar";
@import "ui-components/_variables";
@import "ui-components/_mixins";

$height: 40px;

.block {
  background-color: color("body");

  .item {

    @media (min-width: breakpoint("small")) {
      display: flex;
    }
    height: 100%;
    align-items: center;
    font-size: size("font--small");
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
    height: $height;
  }

  &__right {
    padding-left: math.div(size("gutter"), 2);
    display: flex;
    align-items: center;
    font-size: size("font--small");

    .item {
      flex: 1;
    }

    @media (max-width: (breakpoint("tiny") - 1px)) {
      flex: 1;
      padding-left: math.div(size("gutter--small"), 2);
    }
  }

  &__left {
    flex: 1;
    gap: size("gutter--mini");
    padding-left: calc(#{size("gutter--mini")} / 2);
    padding-right: 0;

    .item {
      width: 100%;
      height: 100%;
      padding: 0 !important;

      > * + * {
        margin-left: 1rem;
      }

      @media (min-width: breakpoint("medium")) {
        padding-right: 0;
        width: auto;
      }
    }
  }
}

.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.allFiltersButton {
  width: 100%;
  padding: size("gutter--mini") size("gutter");
}

.price {
  display: none;
  color: color("text--muted");

  & > span {
    white-space: nowrap;
    align-self: center;

    &:first-child {
      margin-right: size("gutter--large");
    }

    &:last-child {
      width: auto;
      margin-left: size("gutter--large");
    }
  }

  .slider {
    flex: 1;
    width: auto !important;
  }
}

.num_results {
  display: none;

  @media (min-width: breakpoint("small")) {
    display: flex;
  }
}
