@import "../../node_modules/@crossroads/shop-views/src/shop-views/AdventCalendar";
@import "ui-components/_variables.scss";

$contentPaddingBottom: size("gutter--mini") + size("gutter--large") * 2;
$containerPadding: 16rem;
$containerPaddingMini:19rem;
$containerPaddingTiny: 22rem;

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
}

.today {
  margin-top: -#{size("header-margin")};
  margin-bottom: size("header-margin");

  @media (max-width: breakpoint("small")) {
    flex-direction: column;
    align-items: center;

    &Image {
      margin-bottom: size("gutter--large");
    }
  }

  &Container {
    display: flex;
    flex-direction: column;
  }

  &Banner {
    height: 400px;
    position: relative;
    border-radius: size("border-radius");
    overflow: hidden;
  }

  &SmallBanner,
  &MediumBanner,
  &LargeBanner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    position: absolute;
    top: -#{size("header-margin")};
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
  }

  &SmallBanner {
    @media (max-width: 440px) {
      display: block;
    }
  }

  &MediumBanner {
    @media (min-width: 441px) and (max-width: 800px) {
      display: block;
    }
  }

  &LargeBanner {
    @media (min-width: 801px) {
      display: block;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    align-items: center;

    // min-height: 350px;
    z-index: 1;
    flex: 1 1 auto;
    margin-top: size("gutter--large") * 1.2;

    h3 {
      font-size: size("font--medium");
      text-transform: none;
      margin-bottom: 0;
    }

    h2 {
      font-size: size("font--enormous");
      margin-top: 0;
      margin-bottom: size("gutter--small");
    }
  }

  &Description {
    padding: size("gutter--mini");
    max-width: 500px;
    margin-bottom: size("gutter--large") * 1.5;
    background: color("secondary");
    color: #fff;
    text-align: center;
  }

  &ProductList {
    margin-top: size("gutter");

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }

  &ViewAll {
    margin: size("gutter");
    display: block;
    text-align: center;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.button {
  pointer-events: none;

  &:hover {
    background: color("primary") !important;
  }

  .door:active & {
    color: rgba(#fff, 0.5);
    background-color: color("primary", -10%);
    background-size: 100%;
    transition: background 0s;
  }

  .door:hover & {
    background: color("primary", 10%) radial-gradient(circle, transparent 1%, color("primary", 10%) 1%) center/15000%;
  }
}

.landing {
  & > *[class] {
    flex: 1 1 auto;
    background-image: url("/assets/images/christmas.jpg");
    background-position: 0;
    background-size: auto;
    height: calc(100vh - #{size("header-height")});
    display: flex;
    align-items: center;
    text-align: center;
    z-index: 1;
    justify-content: center;
    max-width: 100%;
  }

  @media (min-width: breakpoint("tiny-small")) {
    display: flex;
    align-items: center;
  }

  @media (max-width: (breakpoint("tiny-small") - 1px)) {
    padding-top: size("gutter");
  }

  &,
  h1 {
    color: #fff;
  }

  h1 {
    margin-bottom: size("gutter");

    @media (max-width: (breakpoint("small") - 1px)) {
      font-size: size("font--huge");
    }
  }

  p {
    margin-top: 0;
    margin-bottom: size("gutter--large");
  }

  &Container {
    display: flex;
    flex-direction: column;

    @media (max-width: (breakpoint("medium") - 1px)) {
      margin-top: -#{size("gutter")};
      flex-direction: column;
    }
  }
}
