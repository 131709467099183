@use "sass:math";

@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

.productList {
  @include stretch();

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.item {
  width: 100%;
  float: left;
  min-width: 0;
  padding: math.div(size("grid-gutter"), 2);

  .list__perRow4 & {
    @media (min-width: breakpoint("tiny")) {
      width: 50%;
    }

    @media (min-width: breakpoint("small")) {
      width: 33.33%;
    }

    @media (min-width: breakpoint("medium")) {
      width: 25%;
    }
  }

  .list__perRow3 & {
    @media (min-width: breakpoint("tiny")) {
      width: 50%;
    }

    @media (min-width: breakpoint("medium")) {
      width: 33.333%;
    }
  }

  .list__perRow2 & {
    @media (min-width: breakpoint("medium")) {
      width: 100%;
    }

    @media (min-width: breakpoint("tiny")) {
      width: 50%;
    }
  }
}

.itemInner {
  height: 100%;
}
