@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

$desktop-border: color("border--muted");
$mobile-border: color("border");


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: size("header-height");

  @media (max-width: breakpoint("medium") - 1px) {
    padding-left: 0;
    padding-right: 0;
    background: #fff;
    border-bottom: 1px solid $mobile-border;
  }

  @media (min-width: breakpoint("medium")) {
    height: 4.5rem;
    border-bottom: 1px solid $desktop-border;

    .heading {
      color: color("text--border");
    }
  }
}

.back {
  border: none;
  background: none;
  padding: size("gutter--small");

  svg {
    width: 36px;
    height: 36px;
    transform: rotate(-90deg) scaleY(-1);
  }

  @media (min-width: breakpoint("medium")) {
    display: none;
  }
}

.heading {
  font-weight: bold;
  color: color("primary");
}

.close {
  @include resetInput;
  display: flex;
  align-items: center;
  padding: 0 size("gutter") !important;
  cursor: pointer;

  @media (max-width: breakpoint("medium") - 1px) {
    svg {
      width: 36px;
      height: 36px;
    }
  }
}
